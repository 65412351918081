import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../App";

import logo from "../../assets/LIO-Logos-SE.png";
// import { NavLink } from "react-router-dom";
// import { ROUTER_HOME } from "../App";

const photos: string[] = [
    "/assets/preview-2025/1.png",
    "/assets/preview-2025/2.png",
    "/assets/preview-2025/3.png",
    "/assets/preview-2025/4.png",
    "/assets/preview-2025/5.png",
    "/assets/preview-2025/6.png",
    "/assets/preview-2025/7.png",
    "/assets/preview-2025/8.png",
    "/assets/preview-2025/9.png",
    "/assets/preview-2025/10.png",
    "/assets/preview-2025/11.png",
    "/assets/preview-2025/12.png",
    "/assets/preview-2025/13.png",
    "/assets/preview-2025/14.png",
    "/assets/preview-2025/15.png", 
];

const Preview: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState<number | undefined>();

    return (
        <div className="preview" id="preview">
            {currentIndex !== undefined ? (
                <div className="preview__player">
                    <div className="preview__photo">
                        <img src={photos[currentIndex]} alt="" className="preview__photo-image" />
                    </div>
                    <div className="preview__close" onClick={() => setCurrentIndex(undefined)}>
                        close
                    </div>

                    <div className="preview__button left" onClick={() => movePhoto(-1)}>
                        keyboard_arrow_left
                    </div>
                    <div className="preview__button right" onClick={() => movePhoto(1)}>
                        keyboard_arrow_right
                    </div>
                </div>
            ) : null}
            <div className="preview__center">
                <div className="preview__header">Zajrzyj do środka</div>
                <div className="preview__subheader">
                    Zapoznaj się ze spisem treści oraz merytoryką poradnika. Kliknij na obraz, żeby powiększyć.
                </div>
                <div className="preview-columns">
                    {photos.map((photo, index) => (
                        <div className="preview-columns__cell" key={index} onClick={() => openPhoto(index)}>
                            <div className="preview-columns__cell-wrapper">
                                <img src={photo} className="preview-columns__cell-photo" alt="" />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="preview__buttons">
                    <Link to={ROUTES.Checkout} className="button secondary">
                        Kup teraz <i className="start__buttons-icon">shopping_cart</i>
                    </Link>
                </div>
                <div className="preview__bottomline-big"></div>
                <div className="footer">
                    <div className="footer-column">
                        <div className="footer-column__cell">
                            <div className="footer-column__cell-header">Chcesz dowiedzieć się więcej?</div>
                            <div className="footer-column__cell-description">
                                Zajrzyj na naszą stronę internetową, poświęconą Poradnikowi.
                            </div>
                            <div className="footer-column__cell-buttons">
                                <a
                                    href="https://www.se.com/pl/pl/work/products/product-launch/electrical-installation-guide/"
                                    className="button light"
                                    target="blank"
                                >
                                    Przejdź do strony
                                </a>
                            </div>
                        </div>
                        <div className="footer-column__cell">
                            <div className="footer-column__cell-header">Masz pytania lub wątpliwości?</div>
                            <div className="footer-column__cell-description">
                                Skontaktuj się z Centrum Obsługi Klienta Schneider Electric Polska.
                            </div>
                            <div className="footer-column__cell-buttons">
                                <a
                                    href="https://www.se.com/pl/pl/work/support/customer-care/contact-schneider-electric.jsp"
                                    className="button light"
                                    target="blank"
                                >
                                    Przejdź do strony
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="footer__logo-wrapper">
                        <a className="footer__logo" href="https://www.se.com/pl/pl/" target="blank">
                            <img src={logo} className="footer__logo-image" alt="Schneider Electric" />
                        </a>
                    </div>
                    <div className="footer__links">
                        <Link to={ROUTES.Rodo}>Polityka RODO</Link>
                        <Link to={ROUTES.Terms}>Regulamin</Link>
                    </div>
                </div>
            </div>
        </div>
    );

    function openPhoto(index: number) {
        setCurrentIndex(index);
    }

    function movePhoto(direction: -1 | 1) {
        let newIndex = (currentIndex || 0) + direction;
        newIndex = newIndex < 0 ? photos.length - 1 : newIndex >= photos.length ? 0 : newIndex;
        setCurrentIndex(newIndex);
    }
};

export default Preview;
