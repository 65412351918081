import React from "react";
import about1 from "../../assets/about-column1.png";
import about2 from "../../assets/about-column2.png";
import about3 from "../../assets/about-column3.png";
import about4 from "../../assets/about1.jpg";

const About: React.FC = () => {
    return (
        <div className="about" id="about">
            <div className="about__header">Co wyróżnia Poradnik?</div>
            <div className="about__center">
                <div className="about__section">
                    <div className="about-columns">
                        <div className="about-columns__cell">
                            <div className="about-columns__cell-wrapper">
                                <div className="about-columns__cell-photo">
                                    <img src={about1} className="about-columns__cell-photo-image" alt="Ikona Praktyczność" />
                                </div>
                                <div className="about-columns__cell-header">Praktyczność</div>
                                <div className="about-columns__cell-description">
                                    Publikacja będzie przydatna w codziennej pracy inżynierom elektrykom, który projektują, wykonują
                                    instalacje, nadzorują pracę, dobierają wyposażenie elektryczne oraz serwisują elementy instalacji
                                </div>
                            </div>
                        </div>
                        <div className="about-columns__cell">
                            <div className="about-columns__cell-wrapper">
                                <div className="about-columns__cell-photo">
                                    <img src={about2} className="about-columns__cell-photo-image" alt="Ikona Uniwersalność" />
                                </div>
                                <div className="about-columns__cell-header">Uniwersalność</div>
                                <div className="about-columns__cell-description">
                                    Szeroka baza wiedzy teoretycznej, wykresy, rysunki poglądowe i tabele, uzupełnione przykładami, są
                                    cennym źródłem informacji dla studentów i wykładowców szkół wyższych na kierunkach inżynierskich
                                </div>
                            </div>
                        </div>
                        <div className="about-columns__cell">
                            <div className="about-columns__cell-wrapper">
                                <div className="about-columns__cell-photo">
                                    <img src={about3} className="about-columns__cell-photo-image" alt="Ikona Aktualność" />
                                </div>
                                <div className="about-columns__cell-header">Aktualność</div>
                                <div className="about-columns__cell-description">
                                    Najnowsza uzupełniona wersja poradnika porusza tematy m.in. dotyczące bezpieczeństwa instalacji,
                                    efektywności energetycznej, zabzpieczenia przed przepięciami, elektromobilności i wiele innych
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="about__section-bottomline"></div>
                </div>
                <div className="about__section">
                    <div className="about__split">
                        <div className="about__left">
                            <div className="about__left__photo">
                                <img
                                    src={about4}
                                    className="about__left__photo-image"
                                    alt="Zdjęcie Poradnika Inżyniera Elektryka na stoliku"
                                />
                            </div>
                        </div>
                        <div className="about__right">
                            <div className="about__right-header">Najnowsza wersja (2025)</div>
                            <div className="about__right-text">
                                W nowej odsłonie Poradnika Inżyniera Elektryka można znaleźć rozwiązania techniczne, zgodne z najnowszymi
                                normami międzynarodowymi IEC. Publikacja ta została wzbogacona, między&nbsp;innymi,&nbsp;o:
                            </div>
                            <ul className="about__right-list">
                                <li className="about__right-list__element">
                                    szczegółowe opisy i rodzaje prosumenckich instalacji elektrycznych zgodnych z normą IEC 60364-8-82
                                </li>
                                <li className="about__right-list__element">
                                    zmiany związane z integracją produkcji energii słonecznej na własne potrzeby
                                </li>
                                <li className="about__right-list__element">
                                    ochrona przed porażeniem prądem elektrycznym w instalacjach prosumenkich
                                </li>
                                <li className="about__right-list__element">dobór wyłączników RCD w obecności prądów upływowych DC</li>
                                <li className="about__right-list__element">trendy w sektorze e-mobility</li>
                                <li className="about__right-list__element">rodzaje, działanie i infrastruktura pojazdów elektrycznych</li>
                                <li className="about__right-list__element">
                                    integracja urządzeń zasilających pojazdy elektryczne z istniejącą infrastrukturą
                                </li>
                                <li className="about__right-list__element">i wiele innych!</li>
                            </ul>
                        </div>
                    </div>
                    <div className="about__section-bottomline"></div>
                </div>
            </div>
        </div>
    );
};

export default About;
