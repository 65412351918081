import React from "react";
import { Link } from "react-router-dom";
import { ROUTER_HOME, ROUTES } from "../../App";
import startimg from "../../assets/poradnik-2025.png";

const Start: React.FC = () => {
    return (
        <div className="start">
            <div className="start__center">
                <div className="start__left">
                    <div className="start__left__photo">
                        <img src={startimg} className="start__left__photo-image" alt="" />
                    </div>
                </div>
                <div className="start__right">
                    <div className="start__header">Poradnik Inżyniera Elektryka 2025</div>
                    <div className="start__text">
                        Nowy, zaktualizowany Poradnik Inżyniera Elektryka przeznaczony dla profesjonalistów, którzy projektują, nadzorują
                        lub zajmują się utrzymaniem instalacji elektrycznych. Ponad 660 stron praktycznej wiedzy o nowoczesnych,
                        bezpiecznych i efektywnych rozwiązaniach elektrycznych, w zgodzie z międzynarodowymi normami.
                    </div>
                    <div className="start__text">
                        Już dziś dzięki najnowszej wersji poradnika dowiedz&nbsp;się:
                        <b>
                            <br />
                            "Które rozwiązanie techniczne zagwarantuje spełnienie obowiązujących zasad bezpieczeństwa?"
                        </b>
                        <br />
                        <br />
                    </div>
                    <Link to={ROUTES.Checkout} className="button secondary">
                        Kup teraz <i className="start__buttons-icon">shopping_cart</i>
                    </Link>
                    <div className="start__text">
                        <b>
                            <br />
                            Wciąż możesz kupić poprzednią edycję Poradnika Inżyniera Elektryka z 2020 roku, teraz w PROMOCYJNEJ CENIE!
                        </b>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Start;
